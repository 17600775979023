import React from "react";
import AlgorithmViewer from "./AlgorithmViewer";

const Algoritmalar = () => {
  return (
    <section>
      <AlgorithmViewer />
    </section>
  );
};

export default Algoritmalar;
